// 搜尋、深色模式、語言切換、下載APP、登入註冊按鈕
'use client';

import { useTheme } from 'next-themes';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchModal, setLoginModal, setUserMenuModal, setNotifyModal } from '@/slices/popSlice';
import { userState } from '@/slices/userSlice';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';

import NotifyAndUser from './NotifyAndUser'; // 通知
import LanguageAndApp from './LanguageAndApp'; // 語言切換＋下載APP
import Hamburger from './Hamburger'; // 漢堡選單

const NavFunction = ({ locale }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useSelector(userState);
    const { theme, setTheme } = useTheme(); // 切換黑白模式

    // 切換夜間模式
    const handleTheme = () => setTheme(theme === 'light' ? 'dark' : 'light');

    return (
        <div className="flex items-center space-x-[10px] [&>button]:filter-pale uxl:space-x-[2px]">
            {/* 搜尋 */}
            <button
                className="ulg:mr-[5px] bxs:mr-0"
                onClick={() => {
                    dispatch(setSearchModal(true));
                    dispatch(setUserMenuModal(false));
                    dispatch(setNotifyModal(false));
                }}
            >
                <Image
                    className="dark:hidden"
                    src="/images/icon/icon_search.svg"
                    width={36}
                    height={36}
                    alt="search-button"
                />
                <Image
                    className="hidden dark:block"
                    src="/images/icon/icon_dark_search.svg"
                    width={36}
                    height={36}
                    alt="search-button"
                />
            </button>

            {/* 切換黑白模式 */}
            <button
                onClick={handleTheme}
                className={`ulg:hidden !ml-[3px] ${user?.uid ? 'uxl:!mr-[8px]' : ''}`}
            >
                <Image
                    className="dark:hidden"
                    src="/images/icon/icon_mode_light.svg"
                    width={36}
                    height={36}
                    alt="toggle-dark-mode"
                />
                <Image
                    className="hidden dark:block"
                    src="/images/icon/icon_mode_dark.svg"
                    width={36}
                    height={36}
                    alt="toggle-dark-mode"
                />
            </button>

            {/* 已登入 通知＋頭像 */}
            <NotifyAndUser user={user} />

            {/* 語言切換＋下載APP */}
            <div className="uxl:hidden">
                <LanguageAndApp locale={locale} t={t} />
            </div>

            {/* 登入註冊 */}
            {!user?.uid && (
                <button
                    onClick={() => dispatch(setLoginModal(true))}
                    className="!filter-none h-[32px] px-[18px] trans text-btn1 text-white bg-jblack rounded-[40px] border-1 border-black9 hover:bg-pale hover:border-pale
          dark:hover:text-[#fff] uxl:hidden"
                >
                    {t('LOGIN')} ｜ {t('REGISTER')}
                </button>
            )}

            {/* 漢堡選單按鈕 */}
            <Hamburger dispatch={dispatch} />
        </div>
    );
};

export default NavFunction;
