// 通知（暫時屏蔽） ＋ 頭像
'use client';

import { useDispatch, useSelector } from 'react-redux';
import { setUserMenuModal, setNotifyModal, popState } from '@/slices/popSlice';
import Image from 'next/image';

const NotifyAndUser = ({ user }) => {
    const dispatch = useDispatch();
    const { showUserMenuModal } = useSelector(popState);

    if (user?.uid)
        return (
            <>
                <span className="hidden border-l-1 border-dashed border-black3  ulg:inline-block !mx-[7px] h-[20px] w-[1px] uxs:!mx-0" />
                <div className="flex items-center space-x-[10px] uxl:space-x-[6px] ulg:hidden ulg:!ml-0 pr-[7px] ulg:pl-[4px] bxs:pr-[3px] bxs:pl-0">
                    {/* 通知 暫時屏蔽 */}
                    {/* <button
                    className="filter-pale"
                    onClick={() => {
                      if (!user?.uid) {
                        dispatch(setLoginModal(true));
                      } else {
                        dispatch(setNotifyModal(!showNotifyModal));
                        dispatch(setUserMenuModal(false));
                      }
                    }}
                  >
                    <Image
                      className="dark:hidden"
                      src="/images/icon/icon_notify.svg"
                      width={36}
                      height={36}
                      alt="notify-button"
                      sizes="36px"
                      loading="lazy"
                    />
                    <Image
                      className="hidden dark:block"
                      src="/images/icon/icon_dark_notify.svg"
                      width={36}
                      height={36}
                      loading="lazy"
                      sizes="36px"
                      alt="notify-button"
                    />
                  </button> */}

                    {/* 使用者頭像 */}
                    <div className="border-1 trans border-transparent hover:border-black2 rounded-[50%] group ulg:hidden">
                        <Image
                            onClick={() => {
                                if (user?.uid) {
                                    dispatch(setUserMenuModal(!showUserMenuModal));
                                    dispatch(setNotifyModal(false));
                                }
                            }}
                            className="rounded-[50%] overflow-hidden border-1 border-black1 trans cp group-hover:border-black2"
                            src={user?.image || '/images/layout/default_head.png'}
                            width={32}
                            height={32}
                            alt="thumbnail"
                        />
                    </div>
                </div>

                {/* <span className="hidden border-r-1 border-dashed border-black3  ulg:inline-block !mx-[7px] h-[20px] w-[1px] uxs:!mx-0" /> */}
            </>
        );

    return <></>;
};

export default NotifyAndUser;
